<template>
	<div class="loginbox">
		<!-- <div class="title">
			<img src="../assets/imgs/login_tit.png" alt="">
		</div> -->
		<div class="loginform">
			<!-- <div class="frombg"><img class="tit" src="../assets/imgs/login_tit.png" alt=""></div> -->
			<div class="from">
				<div class="cont">
					<div class="dl"><span>系统登录</span></div>
					<div class="bd">
						<el-input size="large" placeholder="请输入账号" prefix-icon="iconfont icon-zhanghao"
							v-model="username">
						</el-input>
						<el-input size="large" placeholder="请输入密码" prefix-icon="iconfont icon-icon-mima" type="password"
							v-model="password">
						</el-input>
						<div class="clearfix" style="margin-top: 24px;">
							<el-input size="large" prefix-icon="iconfont icon-yanzhengma"
								style="float: left; width: calc(100% - 115px); margin-top: 0" placeholder="请输入验证码"
								oninput="value=value.replace(/[^\d]/g,'')" v-model="confirm">
							</el-input>
							<div class="code" style="float: right" @click="refreshCode">
								<s-identify :identifyCode="identifyCode"></s-identify>
							</div>
						</div>
						<div class="otherinput">
							<el-checkbox v-model="rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
						</div>
						<el-button @click="gotoLogin" :loading="loading" size="large" class="btn" type="primary">
							<span v-if="!loading">登 录</span>
							<span v-else>登 录 中...</span>
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getCodeImg
	} from '@/api/login'
	import SIdentify from "../components/common/identify.vue";
	import Cookies from "js-cookie";
	export default {
		name: "login",
		components: {
			SIdentify: SIdentify,
		},
		data() {
			return {
				username: "",
				password: "",
				confirm: "",
				code: "",
				checked: false,
				token: this.GLOBAL.token,
				identifyCode: "",
				times: "",
				loading: false,
				rememberMe: false
			};
		},
		created() {
			this.getCookie();
			this.refreshCode();

		},
		methods: {
			//获取cookie
			getCookie() {
				const username = Cookies.get("username");
				const rememberMe = Cookies.get('rememberMe')
				this.username = username === undefined ? this.username : username,
					this.rememberMe = rememberMe === undefined ? false : Boolean(rememberMe)
			},
			//获取验证码
			refreshCode() {
				getCodeImg().then(res => {
					this.identifyCode = res.detail.vcode;
					this.times = res.detail.times;
				});
			},
			//登录
			gotoLogin() {
				var that = this;
				if (this.username.trim() == "") {
					this.$message({
						message: "用户名不能为空",
						type: "warning",
					});
					return;
				}
				if (this.password.trim() == "") {
					this.$message({
						message: "密码不能为空",
						type: "warning",
						"x-access-token": "",
					});
					return;
				}
				if (this.confirm.trim() == "") {
					this.$message({
						message: "验证码不能为空",
						type: "warning",
					});
					return;
				}
				if (this.confirm != this.identifyCode) {
					this.$message({
						message: "验证码错误",
						type: "warning",
					});
					return;
				}

				if (this.rememberMe) {
					Cookies.set("username", this.username, {
						expires: 30
					});
					Cookies.set('rememberMe', this.rememberMe, {
						expires: 30
					});
				} else {
					Cookies.remove("username");
					Cookies.remove('rememberMe');
				}

				this.loading = true;
				let loginForm = {
					username: this.username,
					password: this.password,
					confirm: this.confirm,
					times: this.times
				}
				this.$store.dispatch("Login", loginForm).then((res) => {
					let timer = setTimeout(() => {
						if(res.level.levelid == '-1'){
							this.$router.push({
								path: "/daping"
							}).catch(() => {});
						}else{
							this.$router.push({
								path: "/index"
							}).catch(() => {});
						}
						
						clearTimeout(timer)

					}, 1000)
				}).catch(() => {
					this.loading = false;
					this.refreshCode();
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.loginbox {
		width: 100%;
		height: 100%;
		background-image: url(../assets/imgs/login_bg2.jpg), linear-gradient(to bottom, #010f33, #0172b4);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		min-width: 1200px;
		position: relative;

		.title {
			position: absolute;
			top: 14%;
			left: 0;
			right: 0;

			img {
				display: block;
				width: 740px;
				height: auto;
				margin: auto;
			}
		}
	}

	.loginform {
		width: 1200px;
		margin: auto;
		height: 100%;
		position: relative;
	}

	.loginbox .from {
		width: 384px;
		height: 408px;
		position: absolute;
		top: 5%;
		right: 36px;
		margin: auto;
		bottom: 0;
		/* background-image: linear-gradient(to bottom, rgba(17, 129, 209, 0.7), rgba(13, 61, 148, 0.7)); */
		background: #fff;
		box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.1);
		padding: 32px 40px;
		box-sizing: border-box;
		border-radius: 8px;
		z-index: 10;
	}

	.loginbox .frombg {
		background-image: url(../assets/imgs/login_formbg.png);
		background-repeat: no-repeat;
		background-position: center;
		z-index: 1;
		position: absolute;
		width: 440px;
		height: 467px;
		right: 8px;
		top: 10%;
		margin: auto;
		bottom: 0;

	}


	.loginbox .frombg .tit{
		width: 440px;
		position: absolute;
    left: 0;
    top: -61px;
    right: 0;
    margin: auto;
	}
	.loginbox .dl {
		text-align: center;
		color: #30bdb6;
	}

	.loginbox .dl span {
		font-size: 22px;
		letter-spacing: 1px;
	}


	.codebox {
		position: relative;
		padding-right: 120px;
	}

	.codebox img {
		position: absolute;
		right: 0;
		bottom: 1px;
		width: auto;
		height: 38px;
		z-index: 5;
		cursor: pointer;
	}

	.loginbox .btn {
		width: 100%;
		font-size: 16px;
		background-image: linear-gradient(to bottom, #00c3a4, #00c2ac);
		border-color: #30bdb6;
	}

	.loginbox .bd .el-input {
		margin-top: 24px;

		::v-deep .el-input__inner {
			border-radius: 0;
			/* background-color: #135ea4;*/
			border-color: #30bdb6;
			color: #666; 
			border-radius: 4px;
			padding-left: 36px;

			&::placeholder {
				/* color: #30bdb6; */
				color: #afafaf;
			}
		}

		::v-deep .el-input__prefix {
			color: #30bdb6;
			/* color: #666; */
			width: 30px;
			text-align: center;
			i{
				font-size: 22px;
			}
		}
	}


	.otherinput {
		margin-top: 10px;
		.el-checkbox__input.is-checked+.el-checkbox__label{
			color: #00c2ac !important;
		}
		.el-checkbox__label{
			color: #00c2ac !important;
		}
		.el-checkbox__inner{
			background-color: #00c2ac !important; 
		}
		.el-checkbox {
			color: #00c2ac;
		}
	}

	.checkbox {
		margin: 15px 0 5px;
	}

	.el-checkbox .el-checkbox__label {
		font-size: 12px !important;
	}

	.el-select {
		display: block;
	}

	.zhuce {
		display: block;
		margin-bottom: 10px;
		padding: 0 !important;
	}
</style>
